@import url('https://fonts.googleapis.com/css2?family=Alata&family=Inconsolata:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');

:root {
  --fw-padding: 0 88px;
  --fw-padding-xy: 0 60px;
  --fw-padding-xy-mobile: 180px 50px 24px;
  --fw-padding-xy-mobile-lg: 0 50px 24px 50px;

  --color-black: #000;
  --color-white: #fffffa;
  --color-lightPink: #fff3f3;
  --color-contrastPink: #cd19dd;
  --color-brightRed: #de240e;
  --color-lightLilac: #eae7fd;
  --color-softYellow: #fff9e2;
  --color-vividBlue: #0e23de;
  --color-livingGreen: #1bb26b;
  --color-darkGreen: #1bb26b;
  --color-lavendarLilac: hsl(263, 47%, 73%);
  --color-deepLavendar: hsl(263, 30%, 40%);
  --color-summerBlue: cornflowerblue;
  --color-summerOrange: mediumspringgreen;

  --font-main: 'Rethink Sans';
  --font-secondary: 'Inconsolata';
}

*,
body,
div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Rethink Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

h1 {
  font-size: 86px;
  color: #fff;
  font-weight: 600;
}

h2 {
  font-size: 48px;
  line-height: 1.5;
  font-weight: 600;
}

h3 {
  font-size: 32px;
  font-weight: 600;
}

h4 {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 14px;
}

@media (min-width: 800px) {
  h2 {
    font-size: 60px;
  }

  h3 {
    font-size: 40px;
    font-weight: 400;
  }
}

a {
  text-decoration: none;
  color: var(--color-black);
}

.hero-textwrapper {
  max-width: 70%;
  padding-bottom: 100px;
}

.w-100 {
  width: 100%;
}

.flex {
  display: flex;
}

.space-around {
  align-items: center;
  justify-content: space-around;
}
